import {loadStripe} from '@stripe/stripe-js'
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUB_KEY)
const priceListProd = {
  preN: 'price_1H3nSaEq9RpCA04B7a439h8n',
  preK: {
    first: 'price_1H3nRCEq9RpCA04BW5jrEsdx',
    second: 'price_1H3nRgEq9RpCA04B3t0BnCQ2'
  },
  review: {
    morning: {
      elem: 'price_1H3nTvEq9RpCA04BnhoxLCYU',
      high: 'price_1H3nunEq9RpCA04BwzImj6YR'
    },
    allday: {
      lowerGrades: 'price_1H3nUMEq9RpCA04BQ21oJClW',
      higherGrades: 'price_1H3nXdEq9RpCA04B0HeGCwbc'
    }
  },
  backToSchool: 'price_1H3nYmEq9RpCA04B67ixvWvs',
  lang: {
    price70: 'price_1H4ACjEq9RpCA04BRUBs5Zqu',
    price175: 'price_1H3ne3Eq9RpCA04BCtMcrQva',
    price350: 'price_1H4TujEq9RpCA04BiiXbBXJw'
  },
  certificate: 'price_1H3neOEq9RpCA04BCBL9Mlko',
  workshop: {
    price35: 'price_1HEM14Eq9RpCA04BemzZyrT6',
    price70: 'price_1H4k0CEq9RpCA04BL3EB7qDp',
    price105: 'price_1H4k0JEq9RpCA04Bj06SqSF4',
    price175: 'price_1H3nemEq9RpCA04BwsMwFtpz'
  }
}

const priceListDev = {
  preN: 'price_1H3njzEq9RpCA04BdCH4b4bv',
  preK: {
    first: 'price_1H3niaEq9RpCA04Bzv97EvQt',
    second: 'price_1H3nj1Eq9RpCA04Bb47XQDCH'
  },
  review: {
    morning: {
      elem: 'price_1H3nkdEq9RpCA04BS7zvqQ9y',
      high: 'price_1H3nv1Eq9RpCA04BTaganFoi'
    },
    allday: {
      lowerGrades: 'price_1H3nlFEq9RpCA04BIGXl994i',
      higherGrades: 'price_1H3nlFEq9RpCA04BNwtJuLN8'
    }
  },
  backToSchool: 'price_1H3nleEq9RpCA04BD1zqhXZT',
  lang: {
    price70: 'price_1H48wJEq9RpCA04BkpBaujN6',
    price175: 'price_1H3nmJEq9RpCA04BJ1NDleCQ',
    price350: 'price_1H4U3MEq9RpCA04BwHAW420G'
  },
  certificate: 'price_1H3nnDEq9RpCA04BgUYFJrUM',
  workshop: {
    price35: 'price_1HELzGEq9RpCA04BCy8B9zwQ',
    price70: 'price_1H4m9lEq9RpCA04Bsyc1LwK8',
    price105: 'price_1H4m9sEq9RpCA04BQLAfynzw',
    price175: 'price_1H3nnZEq9RpCA04Bbqh849Do'
  }
}

const prices = process.env.GATSBY_STAGE === 'production' ? priceListProd : priceListDev

export async function redirectToCheckout (students) {
  const items = []
  let preNNum = 0
  let preKFirstNum = 0
  let preKSecondNum = 0
  let reviewElemNum = 0
  let reviewHighNum = 0
  let reviewAllLowerNum = 0
  let reviewAllHigerNum = 0
  let backToSchoolNum = 0
  let workshopNum = 0
  let workshop1DayNum = 0
  let workshop2DaysNum = 0
  let workshop3DaysNum = 0
  let langNum = 0
  let lang2DaysNum = 0
  let lang2WeeksNum = 0
  let certificateNum = 0

  students.forEach(el => {
    const g = el['Grade in Jp']
    const stage = g === '未就園' ? '未就園' : g.includes('幼児部') ? '幼児部' : g.includes('小学') ? '小学部' : g.includes('中学') ? '中学部' : (g.includes('高校') || g.includes('高等')) ? '高等部' : g.includes('大人') ? '大人' : null

    if (!stage) {
      return
    }

    const classCats = el['Class Categories'] || []
    const classesPreN = el['Classes PreN'] || []

    if (stage === '未就園') {
      const num = classesPreN.length
      preNNum += num
    }

    if (stage === '幼児部') {
      classCats.forEach(el => {
        if (el === 'kinder1st') {
          preKFirstNum++
        } else if (el === 'kinder2nd') {
          preKSecondNum++
        }
      })
    }

    if (stage === '小学部') {
      let r1 = false
      let r2 = false
      let rA = false
      classCats.forEach(el => {
        if (el === 'review') {
          r1 = true
        } else if (el === 'reviewAft') {
          r2 = true
        }
      })
      if (r1 && r2) {
        rA = true
        if (g === '小学部五年' || g === '小学部六年') {
          reviewAllHigerNum++
        } else {
          reviewAllLowerNum++
        }
      }
      classCats.forEach(el => {
        if (el === 'backToSchool') {
          backToSchoolNum++
        } else if (el === 'workshop') {
          workshopNum++
        } else if (el === 'workshop1Day') {
          workshop1DayNum++
        } else if (el === 'workshop2Days') {
          workshop2DaysNum++
        } else if (el === 'workshop3Days') {
          workshop3DaysNum++
        } else if (el === 'lang') {
          langNum++
        } else if (el === 'lang2Days') {
          lang2DaysNum++
        } else if (el === 'lang2Weeks') {
          lang2WeeksNum++
        } else if (el === 'certificate') {
          certificateNum++
        } else if (el === 'review' && !rA) {
          reviewElemNum++
        }
      })
    }

    if (stage === '中学部') {
      classCats.forEach(el => {
        if (el === 'backToSchool') {
          backToSchoolNum++
        } else if (el === 'workshop') {
          workshopNum++
        } else if (el === 'workshop1Day') {
          workshop1DayNum++
        } else if (el === 'workshop2Days') {
          workshop2DaysNum++
        } else if (el === 'workshop3Days') {
          workshop3DaysNum++
        } else if (el === 'lang') {
          langNum++
        } else if (el === 'lang2Days') {
          lang2DaysNum++
        } else if (el === 'lang2Weeks') {
          lang2WeeksNum++
        } else if (el === 'certificate') {
          certificateNum++
        } else if (el === 'review') {
          reviewHighNum++
        }
      })
    }

    if (stage === '高等部') {
      classCats.forEach(el => {
        if (el === 'backToSchool') {
          backToSchoolNum++
        } else if (el === 'workshop') {
          workshopNum++
        } else if (el === 'workshop1Day') {
          workshop1DayNum++
        } else if (el === 'workshop2Days') {
          workshop2DaysNum++
        } else if (el === 'workshop3Days') {
          workshop3DaysNum++
        } else if (el === 'lang') {
          langNum++
        } else if (el === 'lang2Days') {
          lang2DaysNum++
        } else if (el === 'lang2Weeks') {
          lang2WeeksNum++
        } else if (el === 'certificate') {
          certificateNum++
        } else if (el === 'review') {
          reviewHighNum++
        }
      })
    }

    if (stage === '大人') {
      classCats.forEach(el => {
        if (el === 'backToSchool') {
          backToSchoolNum++
        } else if (el === 'workshop') {
          workshopNum++
        } else if (el === 'workshop1Day') {
          workshop1DayNum++
        } else if (el === 'workshop2Days') {
          workshop2DaysNum++
        } else if (el === 'workshop3Days') {
          workshop3DaysNum++
        } else if (el === 'lang') {
          langNum++
        } else if (el === 'lang2Days') {
          lang2DaysNum++
        } else if (el === 'lang2Weeks') {
          lang2DaysNum++
        } else if (el === 'certificate') {
          certificateNum++
        }
      })
    }
  })

  if (preNNum > 0) {
    items.push({price: prices.preN, quantity: preNNum})
  }

  if (preKFirstNum > 0) {
    items.push({price: prices.preK.first, quantity: preKFirstNum})
  }
  if (preKSecondNum > 0) {
    items.push({price: prices.preK.second, quantity: preKSecondNum})
  }

  if (reviewElemNum > 0) {
    items.push({price: prices.review.morning.elem, quantity: reviewElemNum})
  }
  if (reviewAllLowerNum > 0) {
    items.push({price: prices.review.allday.lowerGrades, quantity: reviewAllLowerNum})
  }
  if (reviewAllHigerNum > 0) {
    items.push({price: prices.review.allday.higherGrades, quantity: reviewAllHigerNum})
  }

  if (reviewHighNum > 0) {
    items.push({price: prices.review.morning.high, quantity: reviewHighNum})
  }

  if (backToSchoolNum > 0) {
    items.push({price: prices.backToSchool, quantity: backToSchoolNum})
  }

  if (langNum > 0) {
    items.push({price: prices.lang.price175, quantity: langNum})
  }
  if (lang2DaysNum > 0) {
    items.push({price: prices.lang.price70, quantity: lang2DaysNum})
  }
  if (lang2WeeksNum > 0) {
    items.push({price: prices.lang.price350, quantity: lang2WeeksNum})
  }

  if (workshopNum > 0) {
    items.push({price: prices.workshop.price175, quantity: workshopNum})
  }
  if (workshop1DayNum > 0) {
    items.push({price: prices.workshop.price35, quantity: workshop1DayNum})
  }
  if (workshop2DaysNum > 0) {
    items.push({price: prices.workshop.price70, quantity: workshop2DaysNum})
  }
  if (workshop3DaysNum > 0) {
    items.push({price: prices.workshop.price105, quantity: workshop3DaysNum})
  }

  if (certificateNum > 0) {
    items.push({price: prices.certificate, quantity: certificateNum})
  }

  const stripe = await stripePromise
  const {error} = await stripe.redirectToCheckout({
    lineItems: items,
    mode: 'payment',
    successUrl: `${window.location.origin}/payment-success/`,
    cancelUrl: `${window.location.origin}/payment-cancelled/`
  })
  if (error) {
    console.warn('Error:', error)
  }
}
