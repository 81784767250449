export const gender = [
  {value: '男'},
  {value: '女'},
  {value: 'その他'},
  {value: '無回答'}
]

export const payment = [
  {value: 'クレジットカード', label: 'クレジットカード'},
  {value: 'チェック', label: 'チェック郵送'}
]

export const yesNo = [
  {value: 'true', label: 'はい'},
  {value: 'false', label: 'いいえ'}
]

export const newsletter = [
  {value: 'はい', label: 'はい'},
  {value: 'いいえ', label: 'いいえ'},
  {value: '登録済み', label: '登録済み'}
]

export const media = [
  {value: '全て可', label: '全て可(ウェブサイト、新聞など含む)'},
  {value: '限定可', label: '学園内での配布物のみ可'},
  {value: '不可', label: '不可'}
]

export const gradesInJp = [
  {value: '未就園', label: '未就園'},
  {value: '幼児部年少', label: '幼児部年少(3歳の未就園児も可)'},
  {value: '幼児部年中', label: '幼児部年中'},
  {value: '幼児部年長', label: '幼児部年長'},
  {value: '小学部一年', label: '小学一年'},
  {value: '小学部二年', label: '小学二年'},
  {value: '小学部三年', label: '小学三年'},
  {value: '小学部四年', label: '小学四年'},
  {value: '小学部五年', label: '小学五年'},
  {value: '小学部六年', label: '小学六年'},
  {value: '中学部一年', label: '中学一年'},
  {value: '中学部二年', label: '中学二年'},
  {value: '中学部三年', label: '中学三年'},
  {value: '高等部一年', label: '高校一年'},
  {value: '高等部二年', label: '高校二年'},
  {value: '高等部三年', label: '高校三年'}
]

export const gradesInUs = [
  {value: 'Pre-Nursery', label: 'Pre-Nursery(3歳未満)'},
  {value: 'Nursery', label: 'Nursery(3-4歳)'},
  {value: 'Pre-K', label: 'Pre-K (4-5歳)'},
  {value: 'Kindergarten', label: 'Kindergarten(5-6歳)'},
  {value: '1st Grade', label: '1st Grade'},
  {value: '2nd Grade', label: '2nd Grade'},
  {value: '3rd Grade', label: '3rd Grade'},
  {value: '4th Grade', label: '4th Grade'},
  {value: '5th Grade', label: '5th Grade'},
  {value: '6th Grade', label: '6th Grade'},
  {value: '7th Grade', label: '7th Grade'},
  {value: '8th Grade', label: '8th Grade'},
  {value: '9th Grade', label: '9th Grade'},
  {value: '10th Grade', label: '10th Grade'},
  {value: '11th Grade', label: '11th Grade'},
  {value: '12th Grade', label: '12th Grade'}
]

export const courses = [
  {stage: '幼児部', value: ['kinder2nd', 'lang']},
  {stage: '小学部', value: ['reviewAm', 'reviewPm', 'backToSchool', 'lang', 'workshop']},
  {stage: '中学部', value: ['reviewAm', 'reviewPm', 'backToSchool', 'lang', 'workshop']},
  {stage: '高等部', value: ['reviewAm', 'reviewPm', 'backToSchool', 'lang', 'workshop']},
  {stage: '大人', value: ['backToSchool', 'lang', 'workshop']}
]

export const classesPreNursery = [
  // {value: '8月4日'},
  // {value: '8月11日'},
  // {value: '8月18日'},
  // {value: '8月25日'},
  {value: '9月1日'}
]

export const tshirts = [
  {value: 'Youth XS', label: 'Youth XS - 園児向け'},
  {value: 'Youth S', label: 'Youth S - 米国子供サイズ 6-8'},
  {value: 'Youth M', label: 'Youth M - 米国子供サイズ 10-12'},
  {value: 'Youth L', label: 'Youth L - 米国子供サイズ 14-16'},
  {value: 'S', label: 'S - 米国大人サイズ'},
  {value: 'M', label: 'M - 米国大人サイズ'},
  {value: 'L', label: 'L - 米国大人サイズ'}
]

export const languageClass = [
  {value: '英語', label: '英語'},
  {value: '日本語', label: '日本語'}
]

export const languageSkill = [
  {value: '日常的に使用している', label: '日常的に使用している'},
  {value: '簡単な話ができる', label: '簡単な話ができる'},
  {value: 'ほとんど話せない', label: 'ほとんど話せない'}
]
