import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {colors, fontfamily} from '../lib/variables'
import {Link} from 'gatsby'

const StyledBasePortableText = styled.div`
  max-width: 640px;
  margin: 0 auto;
  h1,
  h2,
  h3,
  h4{
    text-align: left;
    font-family: ${fontfamily.jaRounded};
    margin-bottom: 2.4rem;
    padding-top: 3.6rem;
    color: ${colors.navy};
    position: relative;
    padding-bottom: 0.8rem;
    font-weight: 700;
    & :after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background: ${colors.yellow};
      height: 2px;
      width: 100%;
      border-radius: 4px;
    }
  }

  h2{
    font-size: 2.0rem;
  }
  h3{
    font-size: 1.8rem;
    padding-top: 2.4rem;
    margin-bottom: 1.6rem;
    padding-bottom: 0rem;
    & :after{
      display: none;
    }
  }
  h4{
    font-size: 1.6rem;
    padding-top: 1.8rem;
    margin-bottom: 1.4rem;
    padding-bottom: 0rem;
    & :after{
      display: none;
    }
  }

  ul,
  ol{
    font-size: 1.6rem;
    list-style-position: outside;
    margin: 0 0 0 2.6rem;
    padding: 0;
    line-height: 1.75;
  }
  li{
    margin: 0 0 2.4rem 0;
  }
  strong,
  bold{
    color: ${colors.navy};
  }
 `

const StyledLink = styled(props => <Link {...props} />)`
  color: ${colors.blue};
  font-weight: 400;
  font-size: 1.6rem;
`

const ExternalLink = styled.a`
  color: ${colors.blue};
  font-weight: 400;
  font-size: 1.6rem;
`

const FileDownload = styled.a`
  color: ${colors.blue};
  font-weight: 400;
  font-size: 1.6rem;
`

const Heading = props => {
  return (
    <>
      <StyledBasePortableText>
        <h2>注意事項</h2>
        <p>お申し込みの前に必ず募集要項・参加規約をご確認ください。</p>
        <p>
          <ExternalLink target='_blank' href='https://www.nyikueionlinesummer.com/'>募集要項へ</ExternalLink>(サマーキャンプ特設サイトが開きます)
        </p>
        <p>
          <ExternalLink target='_blank' href='/terms/summer-2020-term2/'>参加規約へ</ExternalLink>
        </p>

        <h2>クラスの定員について</h2>
        <p>下記ウェブサイトにてクラスの定員状況を公開しております。<br />
          <b>△</b>: 定員間近です。お早めにお申し込みください。<br />
          <b>×</b>: 定員に達したためウェイティングとなります。参加ができない場合は返金となります。<br />
          <ExternalLink href='https://airtable.com/shrVSrGKFuXEuOavv/tbli24mOPNcXv7qgm' target='_blank'>定員状況へ</ExternalLink>
        </p>

        <h2>最少開講人数について</h2>
        <p>
          第2タームの全てのクラス(親子教室、幼児部、ワークショップなどを含む)の最少開講人数を2名と設定いたします。<br />
          開講中止となった授業に関しましては、下記の開講確定日にご連絡をいたします。また、開講されなかった授業分の代金を返金致します。
        </p>
        <p>
          前期 Week1・2(8/3-8/14)開講確定日: <s>7月25日 午前</s> 7月29日 正午<br />
          後期 Week3(8/17-9/4)開講確定日: <s>8月8日 午前</s> 8月12日 午後<br />
          後期 Week4(8/24-8/28)開講確定日: 8月19日 午後<br />
          後期 Week5(8/31-9/4)開講確定日: 8月26日 午後<br />
        </p>

        <h2>お申し込みにはGmailが必要です</h2>
        <p>オンライン授業・保育はGoogle Meetを利用して行うため、Gmailのメールアドレスが必要となります。</p>
        <p>幼児部のお申し込みには保護者のGmailを、小学部以上のお申し込みには本人(児童・生徒)のGmailをご登録ください。</p>
        <p>Gmailをお持ちでない方は下記リンク先よりご取得いただけます。<br />
          <ExternalLink href='https://gmail.com/' target='_blank'>Gmailウェブサイトへ</ExternalLink>
        </p>

        <h2>推奨ブラウザについて</h2>
        <p>最新版のGoogle ChromeまたはSafariによるご利用を推奨しております。<br />
          その他最新版の主要ブラウザFireFox, Edgeによる動作確認も行っております。Internet Explolerはサポート及び動作対象外です。
        </p>

      </StyledBasePortableText>
    </>
  )
}
export default Heading
