import {Field, getIn} from 'formik'
import React from 'react'
import {CheckboxCheck, CheckboxInput, CheckboxWrap, InvalidFeedbck} from '../components/summer-2020-form-sytle'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

export const weekdays = {
  0: {
    en: 'Mon',
    ja: '月曜日'
  },
  1: {
    en: 'Tue',
    ja: '火曜日'
  },
  2: {
    en: 'Wed',
    ja: '水曜日'
  },
  3: {
    en: 'Thu',
    ja: '木曜日'
  },
  4: {
    en: 'Fri',
    ja: '金曜日'
  }
}

export const days = {
  week1: {
    0: '8月3日(月)',
    1: '8月4日(火)',
    2: '8月5日(水)',
    3: '8月6日(木)',
    4: '8月7日(金)'
  },
  week2: {
    0: '8月10日(月)',
    1: '8月11日(火)',
    2: '8月12日(水)',
    3: '8月13日(木)',
    4: '8月14日(金)'
  },
  week3: {
    0: '8月17日(月)',
    1: '8月18日(火)',
    2: '8月19日(水)',
    3: '8月20日(木)',
    4: '8月21日(金)'
  },
  week4: {
    0: '8月24日(月)',
    1: '8月25日(火)',
    2: '8月26日(水)',
    3: '8月27日(木)',
    4: '8月28日(金)'
  },
  week5: {
    0: '8月31日(月)',
    1: '9月1日(火)',
    2: '9月2日(水)',
    3: '9月3日(木)',
    4: '9月4日(金)'
  }
}

function getClassPrice (stage, _class) {
  if (!_class) {
    return
  }
  if (stage === '小学部') {
    if (_class.includes('終日')) {
      return 400
    } else if (_class.includes('午前')) {
      return 225
    } else if (_class.includes('午後')) {
      return 175
    } else {
      return 0
    }
  } else if (stage === '幼児部') {
    if (_class.includes('終日')) {
      return 375
    } else if (_class.includes('午前')) {
      return 215
    } else if (_class.includes('午後')) {
      return 160
    } else {
      return 0
    }
  }
}

export function totalAmountEmail (students, classGroups) {
  let classes = []
  const cats = []
  students.forEach(el => {
    const c = [
      ...el.studentClasses.kinder1st,
      ...el.studentClasses.kinder2nd,
      ...el.studentClasses.reviewAm,
      ...el.studentClasses.reviewPm,
      ...el.studentClasses.backToSchool,
      ...el.studentClasses.lang,
      ...el.studentClasses.workshop
    ]
    classes = [...classes, ...c]
  })
  classes.forEach(el => {
    if (el !== 'limit') {
      const c = classGroups.find(e => e.airtableId === el)
      cats.push(c.cat)
    }
  })
}

export function getClasses (classes, grade, course) {
  let _c = ''
  if (course === 'kinder1st') {
    _c = '幼児部1st'
  } else if (course === 'kinder2nd') {
    _c = '幼児部2nd'
  } else if (course === 'reviewAm') {
    _c = '復習am'
  } else if (course === 'reviewPm') {
    _c = '復習pm'
  } else if (course === 'backToSchool') {
    _c = 'Back To School'
  } else if (course === 'lang') {
    _c = '語学'
  } else if (course === 'workshop') {
    _c = 'ワークショップ'
  }
  return classes.filter(i => {
    return i.grades.includes(grade) && i.course.includes(_c)
  })
}

export function getClass (id, classes) {
  return classes.filter(i => {
    return i.id === id
  })
}

export function eachAmount (student) {
  const name = student.studentNameJa
  const grade = student.studentGradeInJp || ''
  const classes = student.studentClasses || ''
  const stage = getStage(grade)

  if (!grade || !classes) {
    return
  }

  if (stage === '未就園') {
    const classes = student.studentClassesPreN
    return (
      <div>
        <span>{name}</span><br />
        <span>参加する日</span><br />
        {classes && classes.includes('8月4日') && <div> <span>8月4日</span><br /></div>}
        {classes && classes.includes('8月11日') && <div><span>8月11日</span><br /></div>}
        {classes && classes.includes('8月18日') && <div> <span>8月18日</span><br /></div>}
        {classes && classes.includes('8月25日') && <div> <span>8月25日</span><br /></div>}
        {classes && classes.includes('9月1日') && <div> <span>9月1日</span><br /></div>}

        {classes && `合計: $${(classes.length) * 30}`}<br /><br />
      </div>
    )
  } else if (stage === '幼児部' && classes.week1 && classes.week2 && classes.week3 && classes.week4 && classes.week5) {
    if (classes.week1.includes('終日') && classes.week2.includes('終日') && classes.week3.includes('終日') && classes.week4.includes('終日') && classes.week5.includes('終日')) {
      return (
        <div>
          <span>{name}</span><br />
          <span>全5週・終日: $1828.12</span>
        </div>
      )
    } else if (classes.week1.includes('午前') && classes.week2.includes('午前') && classes.week3.includes('午前') && classes.week4.includes('午前') && classes.week5.includes('午前')) {
      return (
        <div>
          <span>{name}</span><br />
          <span>全5週・午前: $1048.12</span>
        </div>
      )
    } else if (classes.week1.includes('午後') && classes.week2.includes('午後') && classes.week3.includes('午後') && classes.week4.includes('午後') && classes.week5.includes('午後')) {
      return (
        <div>
          <span>{name}</span><br />
          <span>全5週・午後: $780</span>
        </div>
      )
    }
  } else if (stage === '小学部' && classes.week1 && classes.week2 && classes.week3 && classes.week4 && classes.week5) {
    if (classes.week1.includes('終日') && classes.week2.includes('終日') && classes.week3.includes('終日') && classes.week4.includes('終日') && classes.week5.includes('終日')) {
      return (
        <div>
          <span>{name}</span><br />
          <span>全5週・終日: $1950</span>
        </div>
      )
    } else if (classes.week1.includes('午前') && classes.week2.includes('午前') && classes.week3.includes('午前') && classes.week4.includes('午前') && classes.week5.includes('午前')) {
      return (
        <div>
          <span>{name}</span><br />
          <span>全5週・午前: $1096.87</span>
        </div>
      )
    } else if (classes.week1.includes('午後') && classes.week2.includes('午後') && classes.week3.includes('午後') && classes.week4.includes('午後') && classes.week5.includes('午後')) {
      return (
        <div>
          <span>{name}</span><br />
          <span>全5週・午後: $853.12</span>
        </div>
      )
    }
  }
  return (
    <div>
      <span>{name}</span><br />
      {classes.week1 && <div><span>{classes.week1}: {formatter.format(getClassPrice(stage, classes.week1))}</span><br /></div>}
      {classes.week2 && <div><span>{classes.week2}: {formatter.format(getClassPrice(stage, classes.week2))}</span><br /></div>}
      {classes.week3 && <div><span>{classes.week3}: {formatter.format(getClassPrice(stage, classes.week3))}</span><br /></div>}
      {classes.week4 && <div><span>{classes.week4}: {formatter.format(getClassPrice(stage, classes.week4))}</span><br /></div>}
      {classes.week5 && <div><span>{classes.week5}: {formatter.format(getClassPrice(stage, classes.week5))}</span><br /></div>}

      {classes && classes.week1 && classes.week2 && classes.week3 && classes.week4 && classes.week5 && `合計: ${formatter.format(getClassPrice(stage, classes.week1) + getClassPrice(stage, classes.week2) + getClassPrice(stage, classes.week3) + getClassPrice(stage, classes.week4) + getClassPrice(stage, classes.week5))}`}
    </div>
  )
}

export function totalAmount (student) {
  const grade = student.studentGradeInJp || ''
  const classes = student.studentClasses || ''

  if (!grade || !classes) {
    return
  }

  const stage = grade.includes('未就園') ? 'preN' : grade.includes('幼児部') ? '幼児部' : grade.includes('小学部') ? '小学部' : ''

  if (stage === '小学部' || stage === '幼児部') {
    return (
      <div>
        {classes.week1 && <div><span>{classes.week1}: ${getClassPrice(stage, classes.week1)}</span><br /></div>}
        {classes.week2 && <div><span>{classes.week2}: ${getClassPrice(stage, classes.week2)}</span><br /></div>}
        {classes.week3 && <div><span>{classes.week3}: ${getClassPrice(stage, classes.week3)}</span><br /></div>}
        {classes.week4 && <div><span>{classes.week4}: ${getClassPrice(stage, classes.week4)}</span><br /></div>}
        {classes.week5 && <div><span>{classes.week5}: ${getClassPrice(stage, classes.week5)}</span><br /></div>}
      </div>
    )
  }
}

export function totalClasses (students) {
  let amount = 0
  students.map(el => {
    const g = el.studentGradeInJp
    const stage = g === '未就園' ? '未就園' : g.includes('幼児部') ? '幼児部' : g.includes('小学') ? '小学部' : g.includes('中学') ? '中学部' : (g.includes('高校') || g.includes('高等')) ? '高等部' : g.includes('大人') ? '大人' : null

    if (!stage) {
      return
    }

    if (stage === '未就園') {
      const classesPreN = el.studentClassesPreN || []
      const num = classesPreN.length
      amount += num
    } else {
      const classes = [
        ...el.studentClasses.kinder1st,
        ...el.studentClasses.kinder2nd,
        ...el.studentClasses.reviewAm,
        ...el.studentClasses.reviewPm,
        ...el.studentClasses.backToSchool,
        ...el.studentClasses.lang,
        ...el.studentClasses.workshop
      ]
      Array.from(new Set(classes)).filter(v => v).map(cl => {
        if (cl !== 'limit') {
          amount++
        }
      })
    }
  })
  return amount
}

export function Checkbox (props) {
  return (
    <Field name={props.name}>
      {({field, form}) => (
        <CheckboxWrap className={props.wrapperClass}>
          <CheckboxInput
            type='checkbox'
            {...props}
            checked={field.value.includes(props.value)}
            onChange={() => {
              if (field.value.includes(props.value)) {
                const nextValue = field.value.filter(
                  value => value !== props.value
                )
                form.setFieldValue(props.name, nextValue)
              } else {
                const nextValue = field.value.concat(props.value)
                form.setFieldValue(props.name, nextValue)
              }
            }}
          />
          {props.value}
          <CheckboxCheck className='form-control-check' />
        </CheckboxWrap>
      )}
    </Field>
  )
}

export function ErrorMessage ({name}) {
  return (
    <Field
      name={name}
      render={({form}) => {
        const error = getIn(form.errors, name)
        const touch = getIn(form.touched, name)
        return touch && error ? <InvalidFeedbck>{error}</InvalidFeedbck> : null
      }}
    />
  )
}

export function getStage (grade) {
  if (!grade) { return }
  if (grade === '未就園') {
    return '未就園'
  } else if (grade.includes('幼児')) {
    return '幼児部'
  } else if (grade.includes('小学')) {
    return '小学部'
  } else if (grade.includes('中学')) {
    return '中学部'
  } else if (grade.includes('高等') || grade.includes('高校')) {
    return '高等部'
  } else if (grade.includes('大人')) {
    return '大人'
  }
}
