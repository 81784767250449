const prices = {
  preN: 30,
  preK: {
    first: 350,
    second: 525
  },
  review: {
    morning: {
      elem: 550,
      high: 375
    },
    allday: {
      lowerGrades: 800,
      higherGrades: 650
    }
  },
  backToSchool: 175,
  lang: {
    price70: 70,
    price175: 175,
    price350: 350
  },
  certificate: 175,
  workshop: {
    price35: 35,
    price70: 70,
    price105: 105,
    price175: 175
  }
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

export function calcAmountTerm2 (students, classGroups) {
  const allClassCats = []
  let amount = 0
  let preNNum = 0
  let preKFirstNum = 0
  let preKSecondNum = 0
  let reviewElemNum = 0
  let reviewHighNum = 0
  let reviewAllLowerNum = 0
  let reviewAllHigerNum = 0
  let backToSchoolNum = 0
  let workshopNum = 0
  let workshop1DayNum = 0
  let workshop2DaysNum = 0
  let workshop3DaysNum = 0
  let langNum = 0
  let lang2DaysNum = 0
  let lang2WeeksNum = 0
  let certificateNum = 0

  students.forEach(el => {
    // let allClasses
    const classCats = []
    const g = el.studentGradeInJp
    const stage = g === '未就園' ? '未就園' : g.includes('幼児部') ? '幼児部' : g.includes('小学') ? '小学部' : g.includes('中学') ? '中学部' : (g.includes('高校') || g.includes('高等')) ? '高等部' : g.includes('大人') ? '大人' : null

    if (!stage) {
      return
    }

    const allClasses = [
      ...el.studentClasses.kinder1st,
      ...el.studentClasses.kinder2nd,
      ...el.studentClasses.reviewAm,
      ...el.studentClasses.reviewPm,
      ...el.studentClasses.backToSchool,
      ...el.studentClasses.lang,
      ...el.studentClasses.workshop
    ].filter(item => item)

    // allClasses = [...allClasses, ...c]
    allClasses.forEach(el => {
      if (el !== 'limit') {
        const c = classGroups.find(e => e.airtableId === el).cat
        classCats.push(c)
      }
    })

    const classesPreN = el.studentClassesPreN || []

    if (stage === '未就園') {
      const num = classesPreN.length
      preNNum += num
    }

    if (stage === '幼児部') {
      classCats.forEach(el => {
        if (el === 'kinder1st') {
          preKFirstNum++
        } else if (el === 'kinder2nd') {
          preKSecondNum++
        }
      })
    }

    if (stage === '小学部') {
      let r1 = false
      let r2 = false
      let rA = false
      classCats.forEach(el => {
        if (el === 'review') {
          r1 = true
        } else if (el === 'reviewAft') {
          r2 = true
        }
      })
      if (r1 && r2) {
        rA = true
        if (g === '小学部五年' || g === '小学部六年') {
          reviewAllHigerNum++
        } else {
          reviewAllLowerNum++
        }
      }
      classCats.forEach(el => {
        if (el === 'backToSchool') {
          backToSchoolNum++
        } else if (el === 'workshop') {
          workshopNum++
        } else if (el === 'workshop1Day') {
          workshop1DayNum++
        } else if (el === 'workshop2Days') {
          workshop2DaysNum++
        } else if (el === 'workshop3Days') {
          workshop3DaysNum++
        } else if (el === 'lang') {
          langNum++
        } else if (el === 'lang2Days') {
          lang2DaysNum++
        } else if (el === 'lang2Weeks') {
          lang2WeeksNum++
        } else if (el === 'certificate') {
          certificateNum++
        } else if (el === 'review' && !rA) {
          reviewElemNum++
        }
      })
    }

    if (stage === '中学部') {
      classCats.forEach(el => {
        if (el === 'backToSchool') {
          backToSchoolNum++
        } else if (el === 'workshop') {
          workshopNum++
        } else if (el === 'workshop1Day') {
          workshop1DayNum++
        } else if (el === 'workshop2Days') {
          workshop2DaysNum++
        } else if (el === 'workshop3Days') {
          workshop3DaysNum++
        } else if (el === 'lang') {
          langNum++
        } else if (el === 'lang2Days') {
          lang2DaysNum++
        } else if (el === 'lang2Weeks') {
          lang2WeeksNum++
        } else if (el === 'certificate') {
          certificateNum++
        } else if (el === 'review') {
          reviewHighNum++
        }
      })
    }

    if (stage === '高等部') {
      classCats.forEach(el => {
        if (el === 'backToSchool') {
          backToSchoolNum++
        } else if (el === 'workshop') {
          workshopNum++
        } else if (el === 'workshop1Day') {
          workshop1DayNum++
        } else if (el === 'workshop2Days') {
          workshop2DaysNum++
        } else if (el === 'workshop3Days') {
          workshop3DaysNum++
        } else if (el === 'lang') {
          langNum++
        } else if (el === 'lang2Days') {
          lang2DaysNum++
        } else if (el === 'lang2Weeks') {
          lang2WeeksNum++
        } else if (el === 'certificate') {
          certificateNum++
        } else if (el === 'review') {
          reviewHighNum++
        }
      })
    }

    if (stage === '大人') {
      classCats.forEach(el => {
        if (el === 'backToSchool') {
          backToSchoolNum++
        } else if (el === 'workshop') {
          workshopNum++
        } else if (el === 'workshop1Day') {
          workshop1DayNum++
        } else if (el === 'workshop2Days') {
          workshop2DaysNum++
        } else if (el === 'workshop3Days') {
          workshop3DaysNum++
        } else if (el === 'lang') {
          langNum++
        } else if (el === 'lang2Days') {
          lang2DaysNum++
        } else if (el === 'lang2Weeks') {
          lang2DaysNum++
        } else if (el === 'certificate') {
          certificateNum++
        }
      })
    }
  })

  if (preNNum > 0) {
    amount += prices.preN * preNNum
  }

  if (preKFirstNum > 0) {
    amount += prices.preK.first * preKFirstNum
  }
  if (preKSecondNum > 0) {
    amount += prices.preK.second * preKSecondNum
  }

  if (reviewElemNum > 0) {
    amount += prices.review.morning.elem * reviewElemNum
  }
  if (reviewAllLowerNum > 0) {
    amount += prices.review.allday.lowerGrades * reviewAllLowerNum
  }
  if (reviewAllHigerNum > 0) {
    amount += prices.review.allday.higherGrades * reviewAllHigerNum
  }

  if (reviewHighNum > 0) {
    amount += prices.review.morning.high * reviewHighNum
  }

  if (backToSchoolNum > 0) {
    amount += prices.backToSchool * backToSchoolNum
  }

  if (langNum > 0) {
    amount += prices.lang.price175 * langNum
  }
  if (lang2DaysNum > 0) {
    amount += prices.lang.price70 * lang2DaysNum
  }
  if (lang2WeeksNum > 0) {
    amount += prices.lang.price350 * lang2WeeksNum
  }

  if (workshopNum > 0) {
    amount += prices.workshop.price175 * workshopNum
  }
  if (workshop1DayNum > 0) {
    amount += prices.workshop.price35 * workshop1DayNum
  }
  if (workshop2DaysNum > 0) {
    amount += prices.workshop.price70 * workshop2DaysNum
  }
  if (workshop3DaysNum > 0) {
    amount += prices.workshop.price105 * workshop3DaysNum
  }

  if (certificateNum > 0) {
    amount += prices.certificate * certificateNum
  }

  return formatter.format(amount)
}
