import axios from 'axios'
import React, {useEffect, useState} from 'react'
import GraphQLErrorList from '../../../components/graphql-error-list'
import styled from 'styled-components'

import Container from '../../../components/container'
import Seo from '../../../components/seo'
import Heading from '../../../components/heading'
import Layout from '../../../containers/layout'
import Summer2020Term2 from '../../../components/summer-2020-term2'
import Summer2020Term2Form from '../../../components/summer-2020-term2-form'
import Term2Closed from '../../../components/summer-2020-closed-term2'

import {testClasses, testGroups} from '../../../lib/test'

const IndexPage = props => {
  const {data, errors} = props
  const [groups, setGroups] = useState(null)

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  useEffect(() => {
    axios.request({
      method: 'POST',
      baseURL: '/api/registration/getClassGroupsTerm2',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8'
      }
    })
      .then((res) => {
        setGroups(res.data)
      }).catch((error) => {
        console.log(error)
      })
  }, [setGroups])

  return (
    <Layout isHome>
      <Seo />
      <Container>
        <Heading ja={'サマースクール2020\n第二ターム'} en='Summer School & Camp 2020 2nd Term' />
        <Term2Closed />
      </Container>
    </Layout>
  )
}

const Loading = styled.span`
  margin: 8rem auto;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  display: block;
`

export default IndexPage
