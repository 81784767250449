import axios from 'axios'
import {Field, FieldArray, getIn, withFormik} from 'formik'
import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import {toast} from 'react-toastify'
import useSSR from 'use-ssr'
import * as Yup from 'yup'
import {Debug} from '../lib/formik-debug'
import {classesPreNursery, classesElementary, gender, gradesInJp, gradesInUs, languageClass, languageSkill, newsletter, tshirts, yesNo, courses, media} from '../lib/summer2020Term2'
import {Heading, SubHeading, Form, FormGroup, Student, Birthday, Small, Desc, InputWrap, SelectWrap, Label, Input, Select, CheckboxWrap, CheckboxInput, CheckboxCheck, RadioWrap, Radio, RadioInput, Textarea, InvalidFeedbck, ButtonWrapper, Button, ButtonRemove, Message, Error, Amounts, TotalClasses, TotalAmount, HowtoPay, Redirect, Attention, StyledLink, TermsLink, ExternalLink, Week, WeekRow, Pricing, SelcectWithRemove, CourseWrap, RemoveSelect, Divider} from './summer-2020-form-sytle'
import Toastify from './Toastify'

import {totalAmount, eachAmount, totalClasses, Checkbox, ErrorMessage, WeekLabel, getClasses, getStage, totalAmountEmail} from '../lib/helpersTerm2'
import {redirectToCheckout} from '../lib/redirectToCheckout-term2'
import {calcAmountTerm2} from '../lib/calcAmount-term2'
import {calcStudentAmountTerm2} from '../lib/calcStudentAmount-term2'

function emptyStringToNull (value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null
  }
  return value
}

const {isBrowser} = useSSR()
const recaptchaRef = React.createRef()

const notifyError = () => toast.error('送信に失敗しました。\nページを再読込してから、もう一度送信してください。', {containerId: 'Error'})

const InnerForm = props => {
  const {
    isAll,
    groups,
    values,
    touched,
    status,
    errors,
    setStatus,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    submitCount,
    setSubmitting,
    setFieldValue
  } = props
  return (
    <Form onSubmit={handleSubmit}>

      <FieldArray
        name='students'
        render={arrayHelpers => (
          <>
            <Heading>参加者の情報</Heading>
            <div>
              {values.students.map((student, index) => (
                <Student key={index}>
                  {values.students.length > 1 && <SubHeading>参加者 入力欄 ({index + 1})</SubHeading>}
                  <SubHeading first>氏名や誕生日など</SubHeading>
                  <FormGroup className='name'>
                    <InputWrap>
                      <Input
                        name={`students.${index}.studentNameJa`}
                        type='text'
                        className={`form-control ${getIn(touched, `students.${index}.studentNameJa`) ? 'is-touched' : ''} ${getIn(touched, `students.${index}.studentNameJa`) && getIn(errors, `students.${index}.studentNameJa`) ? 'is-invalid' : 'is-valid'} ${getIn(touched, `students.${index}.studentNameJa`) && values.students[index].studentNameJa === '' ? 'is-empty' : ' '}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Label className='form-control-label'>日本語 氏名(育英 太郎)</Label>
                    </InputWrap>
                    <ErrorMessage name={`students.${index}.studentNameJa`} />
                  </FormGroup>

                  <FormGroup className='name'>
                    <InputWrap>
                      <Input
                        name={`students.${index}.studentNameEn`}
                        type='text'
                        className={`form-control ${getIn(touched, `students.${index}.studentNameEn`) ? 'is-touched' : ''} ${getIn(touched, `students.${index}.studentNameEn`) && getIn(errors, `students.${index}.studentNameEn`) ? 'is-invalid' : 'is-valid'} ${getIn(touched, `students.${index}.studentNameEn`) && values.students[index].studentNameEn === '' ? 'is-empty' : ' '}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Label className='form-control-label'>英語 氏名(Taro Ikuei)</Label>
                    </InputWrap>
                    <ErrorMessage name={`students.${index}.studentNameEn`} />
                  </FormGroup>

                  <FormGroup className='email'>
                    <InputWrap>
                      <Input
                        name={`students.${index}.studentEmail`}
                        type='email'
                        className={`form-control ${getIn(touched, `students.${index}.studentEmail`) ? 'is-touched' : ''} ${getIn(touched, `students.${index}.studentEmail`) && getIn(errors, `students.${index}.studentEmail`) ? 'is-invalid' : 'is-valid'} ${getIn(touched, `students.${index}.studentEmail`) && values.students[index].studentEmail === '' ? 'is-empty' : ' '}`}
                        value={values.students[index].studentEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Label className='form-control-label'>Gmail</Label>
                    </InputWrap>
                    <ErrorMessage name={`students.${index}.studentEmail`} />
                    <Attention>幼児部は保護者、小学部以上は本人のGmailをご登録ください。</Attention>
                  </FormGroup>

                  <FormGroup className='gender'>
                    <SelectWrap>
                      <Select
                        name={`students.${index}.studentGender`}
                        className={`form-control ${getIn(touched, `students.${index}.studentGender`) ? 'is-touched' : ''} ${getIn(touched, `students.${index}.studentGender`) && getIn(errors, `students.${index}.studentGender`) ? 'is-invalid' : 'is-valid'} ${getIn(touched, `students.${index}.studentGender`) && values.students[index].studentGender === '' ? 'is-empty' : ' '}`}
                        onChange={handleChange}
                        value={values.students[index].studentGender}
                        onBlur={handleBlur}
                      >
                        <option value='' label='' />
                        {gender.map((el, i) => {
                          return <option key={i} value={el.value} label={el.value}>{el.value}</option>
                        })}
                      </Select>
                      <Label className='form-control-label'>性別</Label>
                    </SelectWrap>
                    <ErrorMessage name={`students.${index}.studentGender`} />
                  </FormGroup>

                  <FormGroup>
                    <Birthday>
                      <FormGroup className='year'>
                        <InputWrap>
                          <Input
                            name={`students.${index}.studentBirthdayYear`}
                            type='number'
                            pattern='\d*'
                            className={`form-control ${getIn(touched, `students.${index}.studentBirthdayYear`) ? 'is-touched' : ''} ${getIn(touched, `students.${index}.studentBirthdayYear`) && getIn(errors, `students.${index}.studentBirthdayYear`) ? 'is-invalid' : 'is-valid'} ${getIn(touched, `students.${index}.studentBirthdayYear`) && values.students[index].studentBirthdayYear === '' ? 'is-empty' : ' '}`}
                            onChange={e => {
                              handleChange(e)
                              setFieldValue(`students.${index}.isStudent`, '')
                              if (e.target.value < 2002) {
                                setFieldValue(`students.${index}.isStudent`, 'false')
                                setFieldValue(`students.${index}.studentGradeInJp`, '大人')
                                setFieldValue(`students.${index}.studentYearsLivingInUs`, '')
                                setFieldValue(`students.${index}.studentClasses`, {kinder1st: [], kinder2nd: [], reviewAm: [], reviewPm: [], backToSchool: [''], lang: [''], workshop: ['']})
                              } else {
                                setFieldValue(`students.${index}.isStudent`, 'true')
                                setFieldValue(`students.${index}.studentGradeInJp`, '')
                                setFieldValue(`students.${index}.studentClasses`, {kinder1st: [], kinder2nd: [], reviewAm: [], reviewPm: [], backToSchool: [], lang: [], workshop: []})
                              }
                            }}
                            onBlur={handleBlur}
                            min='1900'
                            max='2020'
                          />
                          <Label className='form-control-label'>誕生日(西暦)</Label>
                        </InputWrap>
                      </FormGroup>
                      <FormGroup className='month'>
                        <InputWrap>
                          <Input
                            name={`students.${index}.studentBirthdayMonth`}
                            type='number'
                            pattern='\d*'
                            className={`form-control ${getIn(touched, `students.${index}.studentBirthdayMonth`) ? 'is-touched' : ''} ${getIn(touched, `students.${index}.studentBirthdayMonth`) && getIn(errors, `students.${index}.studentBirthdayMonth`) ? 'is-invalid' : 'is-valid'} ${getIn(touched, `students.${index}.studentBirthdayMonth`) && values.students[index].studentBirthdayMonth === '' ? 'is-empty' : ' '}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            min='1'
                            max='12'
                          />
                          <Label className='form-control-label'>月</Label>
                        </InputWrap>
                      </FormGroup>
                      <FormGroup className='day'>
                        <InputWrap>
                          <Input
                            name={`students.${index}.studentBirthdayDay`}
                            type='number'
                            pattern='\d*'
                            className={`form-control ${getIn(touched, `students.${index}.studentBirthdayDay`) ? 'is-touched' : ''} ${getIn(touched, `students.${index}.studentBirthdayDay`) && getIn(errors, `students.${index}.studentBirthdayDay`) ? 'is-invalid' : 'is-valid'} ${getIn(touched, `students.${index}.studentBirthdayDay`) && values.students[index].studentBirthdayDay === '' ? 'is-empty' : ' '}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            min='1'
                            max='31'
                          />
                          <Label className='form-control-label'>日</Label>
                        </InputWrap>
                      </FormGroup>
                    </Birthday>
                    <ErrorMessage name={`students.${index}.studentBirthdayYear`} />
                    <ErrorMessage name={`students.${index}.studentBirthdayMonth`} />
                    <ErrorMessage name={`students.${index}.studentBirthdayDay`} />
                  </FormGroup>
                  <FormGroup className='tshirt'>
                    <SelectWrap>
                      <Select
                        name={`students.${index}.studentTshirt`}
                        className={`form-control ${getIn(touched, `students.${index}.studentTshirt`) ? 'is-touched' : ''} ${getIn(touched, `students.${index}.studentTshirt`) && getIn(errors, `students.${index}.studentTshirt`) ? 'is-invalid' : 'is-valid'} ${getIn(touched, `students.${index}.studentTshirt`) && values.students[index].studentTshirt === '' ? 'is-empty' : ' '}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.students[index].studentTshirt}

                      >
                        <option value='' label='' />
                        {tshirts.map(el => {
                          return <option key={el.value} value={el.value} label={el.label}>{el.label}</option>
                        })}
                      </Select>
                      <Label className='form-control-label'>Tシャツのサイズ</Label>
                    </SelectWrap>
                    <ErrorMessage name={`students.${index}.studentTshirt`} />
                    <Attention>参加者全員にTシャツをプレゼント中です！</Attention>
                  </FormGroup>
                  <FormGroup className='media'>
                    <SelectWrap>
                      <Select
                        name={`students.${index}.studentMedia`}
                        className={`form-control ${getIn(touched, `students.${index}.studentMedia`) ? 'is-touched' : ''} ${getIn(touched, `students.${index}.studentMedia`) && getIn(errors, `students.${index}.studentMedia`) ? 'is-invalid' : 'is-valid'} ${getIn(touched, `students.${index}.studentMedia`) && values.students[index].studentMedia === '' ? 'is-empty' : ' '}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.students[index].studentMedia}
                      >
                        <option value='' label='' />
                        {media.map(el => {
                          return <option key={el.value} value={el.value} label={el.label}>{el.label}</option>
                        })}
                      </Select>
                      <Label className='form-control-label'>写真・動画のメディア掲載の可否</Label>
                    </SelectWrap>
                    <ErrorMessage name={`students.${index}.studentMedia`} />
                  </FormGroup>

                  {(values.students[index].studentBirthdayYear === 2000 || values.students[index].studentBirthdayYear === 2001) &&
                    <FormGroup>
                      <Label visible className='form-control-label'>現在高校生以下ですか？</Label>
                      <RadioWrap>
                        {yesNo.map((el, i) => {
                          return (
                            <Radio key={i} className='form-control-label'>
                              <RadioInput
                                type='radio'
                                name={`students.${index}.isStudent`}
                                value={el.value}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.students[index].isStudent === el.value}
                              />{el.label}
                            </Radio>
                          )
                        })}
                      </RadioWrap>
                      <ErrorMessage name={`students.${index}.isStudent`} />
                    </FormGroup>}

                  {(values.students[index].studentBirthdayYear >= 2002 || values.students[index].isStudent === 'true') &&
                    <>
                      <SubHeading>通学中の学校や学年など</SubHeading>
                      <FormGroup className='grade'>
                        <SelectWrap>
                          <Select
                            name={`students.${index}.studentGradeInJp`}
                            className={`form-control ${getIn(touched, `students.${index}.studentGradeInJp`) ? 'is-touched' : ''} ${getIn(touched, `students.${index}.studentGradeInJp`) && getIn(errors, `students.${index}.studentGradeInJp`) ? 'is-invalid' : 'is-valid'} ${getIn(touched, `students.${index}.studentGradeInJp`) && values.students[index].studentGradeInJp === '' ? 'is-empty' : ' '}`}
                            onChange={e => {
                              handleChange(e)
                              if (getStage(e.target.value) === '幼児部') {
                                setFieldValue(`students.${index}.studentClasses`, {kinder1st: [], kinder2nd: [''], reviewAm: [], reviewPm: [], backToSchool: [], lang: [''], workshop: []})
                              } else if (getStage(e.target.value) === '小学部') {
                                setFieldValue(`students.${index}.studentClasses`, {kinder1st: [], kinder2nd: [], reviewAm: [''], reviewPm: [''], backToSchool: [''], lang: [''], workshop: ['']})
                              } else if (getStage(e.target.value) === '中学部') {
                                setFieldValue(`students.${index}.studentClasses`, {kinder1st: [], kinder2nd: [], reviewAm: [''], reviewPm: [''], backToSchool: [''], lang: [''], workshop: ['']})
                              } else if (getStage(e.target.value) === '高等部') {
                                setFieldValue(`students.${index}.studentClasses`, {kinder1st: [], kinder2nd: [], reviewAm: [''], reviewPm: [''], backToSchool: [''], lang: [''], workshop: ['']})
                              } else {
                                setFieldValue(`students.${index}.studentClasses`, {kinder1st: [], kinder2nd: [], reviewAm: [''], reviewPm: [''], backToSchool: [''], lang: [''], workshop: ['']})
                              }
                            }}
                            onBlur={handleBlur}
                            value={values.students[index].studentGradeInJp}
                          >
                            <option value='' label='' />
                            {gradesInJp.map(el => {
                              return <option key={el.value} value={el.value} label={el.label}>{el.label}</option>
                            })}
                          </Select>
                          <Label className='form-control-label'>参加時の日本式での学年</Label>
                        </SelectWrap>
                        <ErrorMessage name={`students.${index}.studentGradeInJp`} />
                      </FormGroup>

                      <FormGroup className='grade'>
                        <SelectWrap>
                          <Select
                            name={`students.${index}.studentGradeInUs`}
                            className={`form-control ${getIn(touched, `students.${index}.studentGradeInUs`) ? 'is-touched' : ''} ${getIn(touched, `students.${index}.studentGradeInUs`) && getIn(errors, `students.${index}.studentGradeInUs`) ? 'is-invalid' : 'is-valid'} ${getIn(touched, `students.${index}.studentGradeInUs`) && values.students[index].studentGradeInUs === '' ? 'is-empty' : ' '}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.students[index].studentGradeInUs}
                          >
                            <option value='' label='' />
                            {gradesInUs.map(el => {
                              return <option key={el.value} value={el.value} label={el.label}>{el.label}</option>
                            })}
                          </Select>
                          <Label className='form-control-label'>参加時のアメリカ式での学年</Label>
                        </SelectWrap>
                        <ErrorMessage name={`students.${index}.studentGradeInUs`} />
                      </FormGroup>

                      <FormGroup className='placeOfBirth'>
                        <InputWrap>
                          <Input
                            name={`students.${index}.studentPlaceOfBirth`}
                            type='text'
                            className={`form-control ${getIn(touched, `students.${index}.studentPlaceOfBirth`) ? 'is-touched' : ''} ${getIn(touched, `students.${index}.studentPlaceOfBirth`) && getIn(errors, `students.${index}.studentPlaceOfBirth`) ? 'is-invalid' : 'is-valid'} ${getIn(touched, `students.${index}.studentPlaceOfBirth`) && values.students[index].studentPlaceOfBirth === '' ? 'is-empty' : ' '}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Label className='form-control-label'>出生国</Label>
                        </InputWrap>
                        <ErrorMessage name={`students.${index}.studentPlaceOfBirth`} />
                      </FormGroup>

                      <FormGroup className='yearLivingInUs'>
                        <InputWrap>
                          <Input
                            name={`students.${index}.studentYearsLivingInUs`}
                            type='number'
                            pattern='\d*'
                            className={`form-control ${getIn(touched, `students.${index}.studentYearsLivingInUs`) ? 'is-touched' : ''} ${getIn(touched, `students.${index}.studentYearsLivingInUs`) && getIn(errors, `students.${index}.studentYearsLivingInUs`) ? 'is-invalid' : 'is-valid'} ${getIn(touched, `students.${index}.studentYearsLivingInUs`) && values.students[index].studentYearsLivingInUs === '' ? 'is-empty' : ' '}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            min='0'

                          />
                          <Label className='form-control-label'>在米年数</Label>
                          <ErrorMessage name={`students.${index}.studentYearsLivingInUs`} />
                        </InputWrap>
                      </FormGroup>

                      <FormGroup className='studentSchool'>
                        <InputWrap>
                          <Input
                            name={`students.${index}.studentSchool`}
                            type='text'
                            className={`form-control ${getIn(touched, `students.${index}.studentSchool`) ? 'is-touched' : ''} ${getIn(touched, `students.${index}.studentSchool`) && getIn(errors, `students.${index}.studentSchool`) ? 'is-invalid' : 'is-valid'} ${getIn(touched, `students.${index}.studentSchool`) && values.students[index].studentSchool === '' ? 'is-empty' : ' '}`}
                            onChange={handleChange}
                            onBlur={handleBlur}

                          />
                          <Label className='form-control-label'>在学校名{(values.students[index].studentGradeInJp.includes('未就園') || values.students[index].studentGradeInJp.includes('幼児部')) && <Small>(任意)</Small>}</Label>
                        </InputWrap>
                        <ErrorMessage name={`students.${index}.studentSchool`} />
                      </FormGroup>

                      <FormGroup className='studentSchool'>
                        <InputWrap>
                          <Input
                            name={`students.${index}.studentSchoolLocation`}
                            type='text'
                            className={`form-control ${getIn(touched, `students.${index}.studentSchoolLocation`) ? 'is-touched' : ''} ${getIn(touched, `students.${index}.studentSchoolLocation`) && getIn(errors, `students.${index}.studentSchoolLocation`) ? 'is-invalid' : 'is-valid'} ${getIn(touched, `students.${index}.studentSchoolLocation`) && values.students[index].studentSchoolLocation === '' ? 'is-empty' : ' '}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Label className='form-control-label'>在学校所在地{(values.students[index].studentGradeInJp.includes('未就園') || values.students[index].studentGradeInJp.includes('幼児部')) && <Small>(任意)</Small>}</Label>
                        </InputWrap>
                        <ErrorMessage name={`students.${index}.studentSchoolLocation`} />
                      </FormGroup>

                      <FormGroup className='studentSchool'>
                        <InputWrap>
                          <Input
                            name={`students.${index}.studentWeekendSchool`}
                            type='text'
                            className={`form-control ${getIn(touched, `students.${index}.studentWeekendSchool`) ? 'is-touched' : ''} ${getIn(touched, `students.${index}.studentWeekendSchool`) && getIn(errors, `students.${index}.studentWeekendSchool`) ? 'is-invalid' : 'is-valid'} ${getIn(touched, `students.${index}.studentWeekendSchool`) && values.students[index].studentWeekendSchool === '' ? 'is-empty' : ' '}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Label className='form-control-label'>在週末校・塾<Small>(任意)</Small></Label>
                        </InputWrap>
                        <ErrorMessage name={`students.${index}.studentWeekendSchool`} />
                      </FormGroup>

                      <FormGroup>
                        <Label visible className='form-control-label'>ニューヨーク育英学園の生徒ですか？</Label>
                        <RadioWrap>
                          {yesNo.map((el, i) => {
                            return (
                              <Radio key={i} className='form-control-label'>
                                <RadioInput
                                  type='radio'
                                  name={`students.${index}.studentJcs`}
                                  value={el.value}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  checked={values.students[index].studentJcs === el.value}
                                />{el.label}
                              </Radio>
                            )
                          })}
                        </RadioWrap>
                        <ErrorMessage name={`students.${index}.studentJcs`} />
                      </FormGroup>

                      {values.students[index].studentJcs === 'true' &&
                        <FormGroup className='studentSchool'>
                          <InputWrap>
                            <Input
                              name={`students.${index}.studentJcsClass`}
                              type='text'
                              className={`form-control ${getIn(touched, `students.${index}.studentJcsClass`) ? 'is-touched' : ''} ${getIn(touched, `students.${index}.studentJcsClass`) && getIn(errors, `students.${index}.studentJcsClass`) ? 'is-invalid' : 'is-valid'} ${getIn(touched, `students.${index}.studentJcsClass`) && values.students[index].studentJcsClass === '' ? 'is-empty' : ' '}`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <Label className='form-control-label'>ニューヨーク育英学園の在学部門</Label>
                          </InputWrap>
                          <ErrorMessage name={`students.${index}.studentJcsClass`} />
                        </FormGroup>}
                      <FormGroup>
                        <Label visible className='form-control-label'>昨年育英サマーキャンプに参加されましたか？</Label>
                        <RadioWrap>
                          {yesNo.map((el, i) => {
                            return (
                              <Radio key={i} className='form-control-label'>
                                <RadioInput
                                  type='radio'
                                  name={`students.${index}.studentLastYear`}
                                  value={el.value}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  checked={values.students[index].studentLastYear === el.value}
                                />
                                {el.label}
                              </Radio>
                            )
                          })}
                        </RadioWrap>
                        <ErrorMessage name={`students.${index}.studentLastYear`} />
                      </FormGroup>
                    </>}

                  <SubHeading>申込するサマーキャンプ</SubHeading>
                  <p>期間・時間が重なっているクラスを並行して申し込まないようにご注意ください。Week や 時間に注意してお申し込みください。<br />
                    例）小学部1-4年生の4時間目は、「音楽/体育コース」と「英語コース」が同じ時間帯ですので、どちらか片方しか履修できません。
                  </p>
                  <p>
                    Week 1 8/3-8/7(申込終了)<br />
                    Week 2 8/10-8/14(申込終了)<br />
                    Week 3 8/17-8/21(申込終了)<br />
                    Week 4 8/24-8/28(申込終了)<br />
                    Week 5 8/31-9/4
                  </p>

                  <Error>
                    {values.students[index].studentGradeInJp.includes('幼児部') && '幼児部のお申し込みは終了しました。'}
                  </Error>

                  <FormGroup>
                    {(values.students[index].studentGradeInJp.includes('未就園')) && <Label visible className='form-control-label'>参加する日(火曜日のみ)・親子教室</Label>}
                    {(values.students[index].studentGradeInJp.includes('未就園')) && classesPreNursery.map(el => {
                      return (
                        <Checkbox
                          key={el.value}
                          name={`students.${index}.studentClassesPreN`}
                          value={el.value}
                          className={(getIn(errors, `students.${index}.studentClassesPreN`) && getIn(touched, `students.${index}.studentClassesPreN`)) && 'is-invalid'}
                          onBlur={handleBlur}
                        />
                      )
                    })}
                    {(values.students[index].studentGradeInJp.includes('未就園')) && <ErrorMessage name={`students.${index}.studentClassesPreN`} />}

                    {
                      values.students[index].studentGradeInJp && getStage(values.students[index].studentGradeInJp) !== '未就園' &&
                      courses.find(c => c.stage === getStage(values.students[index].studentGradeInJp)).value.map(el => {
                        if (!el.includes('review') && !el.includes('kinder')) {
                          return (
                            getClasses(groups, values.students[index].studentGradeInJp, el).length > 0 &&
                              <CourseWrap key={el}>
                                <Label visible>{el === 'backToSchool' ? '後期　Back To School集中コース　8/17-9/4 (3週間)' : el === 'lang' ? '語学' : 'ワークショップ'}</Label>
                                <ErrorMessage name={`students.${index}.studentClasses.${el}`} />
                                <FieldArray
                                  name={`students.${index}.studentClasses.${el}`}
                                  render={({remove, push}) => (
                                    <>
                                      {
                                        values.students[index].studentClasses[el].map((c, index2) => (
                                          <SelcectWithRemove
                                            key={index2}
                                          >
                                            <SelectWrap className='select'>
                                              <Select
                                                value={values.students[index].studentClasses[el][index2]}
                                                disabled={(status && status.state === 'Success')}
                                                name={`students.${index}.studentClasses.${el}.${index2}`}
                                                onChange={(e) => {
                                                  handleChange(e)
                                                }}
                                                onBlur={(e) => {
                                                  handleBlur(e)
                                                  const val = e.currentTarget.value
                                                  const targetClass = groups.find(el => {
                                                    return el.airtableId === val
                                                  })
                                                  if (targetClass.limitStatus === '×') {
                                                    const val = values.students[index].studentClasses[el]
                                                    val.splice(index2, 1, '')
                                                    setFieldValue(`students.${index}.studentClasses['${el}']`, val)
                                                  }
                                                }}
                                              >
                                                <option value='' label='なし' />
                                                {
                                                  getClasses(groups, values.students[index].studentGradeInJp, el).map(i => {
                                                    const selected = values.students[index].studentClasses[el].includes(i.airtableId)
                                                    if (i.weeks && !i.weeks.includes('Week1') && !i.weeks.includes('Week2') && !i.weeks.includes('Week3') && !i.weeks.includes('Week4')) {
                                                      return (
                                                        <option
                                                          key={i.airtableId}
                                                          value={i.airtableId}
                                                          label={i.startAt ? `${i.limitStatus} ${i.idName} / ${i.startAt} - ${i.endAt}` : `${i.limitStatus} ${i.idName}`}
                                                          disabled={selected || i.limitStatus === '×'}
                                                        >
                                                          {i.startAt ? `${i.limitStatus} ${i.idName} / ${i.startAt} - ${i.endAt}` : `${i.limitStatus} ${i.idName}`}
                                                        </option>
                                                      )
                                                    }
                                                  })
                                                }
                                                <optgroup label='' />
                                              </Select>
                                            </SelectWrap>
                                            {index2 > 0 &&
                                              <RemoveSelect
                                                type='button'
                                                onClick={() => remove(index2)}
                                              />}
                                          </SelcectWithRemove>
                                        ))
                                      }
                                      <ButtonWrapper>
                                        <Button
                                          type='button'
                                          onClick={() => push('')}
                                        >
                                          {el === 'backToSchool' ? 'Back To School' : el === 'lang' ? '語学' : 'ワークショップ'}のクラスを追加
                                        </Button>
                                      </ButtonWrapper>
                                    </>
                                  )}
                                />
                              </CourseWrap>
                          )
                        }
                      })
                    }
                  </FormGroup>

                  {
                    values.students[index].studentGradeInJp.includes('幼児部') &&
                      <FormGroup>
                        <Label visible className='form-control-label'>日本語のレベル</Label>
                        <RadioWrap>
                          {languageSkill.map((el, i) => {
                            return (
                              <Radio key={i} className='form-control-label'>
                                <RadioInput
                                  type='radio'
                                  name={`students.${index}.studentJapaneseLevel`}
                                  value={el.value}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  checked={values.students[index].studentJapaneseLevel === el.value}
                                />{el.label}
                              </Radio>
                            )
                          })}
                        </RadioWrap>
                        <ErrorMessage name={`students.${index}.studentJapaneseLevel`} />
                      </FormGroup>
                  }

                  {
                    values.students[index].studentGradeInJp.includes('幼児部') &&
                      <FormGroup>
                        <Label visible className='form-control-label'>英語のレベル</Label>
                        <RadioWrap>
                          {languageSkill.map((el, i) => {
                            return (
                              <Radio key={i} className='form-control-label'>
                                <RadioInput
                                  type='radio'
                                  name={`students.${index}.studentEnglishLevel`}
                                  value={el.value}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  checked={values.students[index].studentEnglishLevel === el.value}
                                />{el.label}
                              </Radio>
                            )
                          })}
                        </RadioWrap>
                        <ErrorMessage name={`students.${index}.studentEnglishLevel`} />
                      </FormGroup>
                  }

                  {values.students.length > 1 &&
                    <ButtonWrapper>
                      <ButtonRemove
                        type='button' onClick={() => {
                          const result = window.confirm(`参加者 (${index + 1})の入力欄を削除しますか？`)
                          if (result) {
                            arrayHelpers.remove(index)
                          }
                        }}
                      >
                        参加者 ({index + 1})を削除
                      </ButtonRemove>
                    </ButtonWrapper>}
                  {index === values.students.length - 1 &&
                    <>
                      <Divider />
                      <ButtonWrapper student>
                        <Button
                          type='button'
                          onClick={() => arrayHelpers.push({
                            studentNameEn: '',
                            studentNameJa: '',
                            studentEmail: '',
                            studentGender: '',
                            studentBirthdayDay: '',
                            studentBirthdayMonth: '',
                            studentBirthdayYear: '',
                            studentPlaceOfBirth: '',
                            studentGradeInJp: '',
                            studentGradeInUs: '',
                            studentClassesPreN: [],
                            studentClasses: {
                              kinder1st: [],
                              kinder2nd: [],
                              reviewAm: [],
                              reviewPm: [],
                              backToSchool: [],
                              lang: [],
                              workshop: []
                            },
                            studentSchool: '',
                            studentSchoolLocation: '',
                            studentWeekendSchool: '',
                            studentYearsLivingInUs: '',
                            studentLanguageClass: '',
                            studentJapaneseLevel: '',
                            studentEnglishLevel: '',
                            studentBus: '',
                            studentLunch: '',
                            studentJcs: '',
                            studentTshirt: '',
                            studentJcsClass: '',
                            studentLastYear: '',
                            studentMedia: '',
                            isStudent: ''
                          })}
                        >
                          参加者を追加
                        </Button>
                      </ButtonWrapper>
                    </>}
                </Student>

              ))}
            </div>
          </>
        )}
      />

      {
        (values.students.some(el => el.studentBirthdayYear >= 2002) || values.students.some(el => el.isStudent === 'true')) &&
          <>
            <Heading>保護者の連絡先</Heading>
            <FormGroup className='name'>
              <InputWrap>
                <Input
                  variant='standard'
                  name='name'
                  className={`form-control ${touched.name ? 'is-touched' : ''} ${touched.name && errors.name ? 'is-invalid' : 'is-valid'} ${touched.name && values.name === '' ? 'is-empty' : ''}`}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Label className='form-control-label'>保護者氏名</Label>
              </InputWrap>
              <ErrorMessage name='name' />
            </FormGroup>

            <FormGroup className='email'>
              <InputWrap>
                <Input
                  name='email'
                  type='email'
                  className={`form-control ${touched.email ? 'is-touched' : ''} ${touched.email && errors.email ? 'is-invalid' : 'is-valid'} ${touched.email && values.email === '' ? 'is-empty' : ''}`}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Label className='form-control-label'>メールアドレス</Label>
              </InputWrap>
              <ErrorMessage name='email' />
            </FormGroup>

            <FormGroup className='tel'>
              <InputWrap>
                <Input
                  name='tel'
                  type='tel'
                  className={`form-control ${touched.tel ? 'is-touched' : ''} ${touched.tel && errors.tel ? 'is-invalid' : 'is-valid'} ${touched.tel && values.tel === '' ? 'is-empty' : ''}`}
                  value={values.tel}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Label className='form-control-label'>緊急電話番号</Label>
              </InputWrap>
              <ErrorMessage name='tel' />
            </FormGroup>

            <FormGroup className='street'>
              <InputWrap>
                <Input
                  name='street'
                  className={`form-control ${touched.street ? 'is-touched' : ''} ${touched.street && errors.street ? 'is-invalid' : 'is-valid'} ${touched.street && values.street === '' ? 'is-empty' : ''}`}
                  value={values.street}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Label className='form-control-label'>Street Address</Label>
              </InputWrap>
              <ErrorMessage name='street' />
            </FormGroup>

            <FormGroup className='apt'>
              <InputWrap>
                <Input
                  name='apt'
                  className={`form-control ${touched.apt ? 'is-touched' : ''} ${touched.apt && errors.apt ? 'is-invalid' : 'is-valid'} ${touched.apt && values.apt === '' ? 'is-empty' : ''}`}
                  value={values.apt}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Label className='form-control-label'>Apt, Suite, Building<Small>(任意)</Small></Label>
              </InputWrap>
            </FormGroup>

            <FormGroup className='city'>
              <InputWrap>
                <Input
                  name='city'
                  className={`form-control ${touched.city ? 'is-touched' : ''} ${touched.city && errors.city ? 'is-invalid' : 'is-valid'} ${touched.city && values.city === '' ? 'is-empty' : ''}`}
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}

                />
                <Label className='form-control-label'>City</Label>
              </InputWrap>
              <ErrorMessage name='city' />
            </FormGroup>

            <FormGroup className='state'>
              <InputWrap>

                <Input
                  name='state'
                  className={`form-control ${touched.state ? 'is-touched' : ''} ${touched.state && errors.state ? 'is-invalid' : 'is-valid'} ${touched.state && values.state === '' ? 'is-empty' : ''}`}
                  value={values.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Label className='form-control-label'>State</Label>
              </InputWrap>
              <ErrorMessage name='state' />
            </FormGroup>

            <FormGroup className='zipcode'>
              <InputWrap>

                <Input
                  name='zipcode'
                  type='text'
                  className={`form-control ${touched.zipcode ? 'is-touched' : ''} ${touched.zipcode && errors.zipcode ? 'is-invalid' : 'is-valid'} ${touched.zipcode && values.zipcode === '' ? 'is-empty' : ''}`}
                  value={values.zipcode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength='10'
                />
                <Label className='form-control-label'>Zip Code</Label>
              </InputWrap>
              <ErrorMessage name='zipcode' />
            </FormGroup>

            <FormGroup>
              <Label visible className='form-control-label'>育英メルマガに登録しますか？</Label>
              <RadioWrap className='newsletter'>
                {newsletter.map((el, i) => {
                  return (
                    <Radio key={i} className='form-control-label'>
                      <RadioInput
                        type='radio'
                        name='newsletter'
                        value={el.value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.newsletter === el.value}
                      />
                      {el.label}
                    </Radio>
                  )
                })}
              </RadioWrap>
              <ErrorMessage name='newsletter' />
            </FormGroup>
          </>
      }

      {
        (values.students.every(el => el.studentBirthdayYear < 2002) && values.students.every(el => el.isStudent === 'false')) &&
          <>
            <Heading>電話番号・住所などの連絡先</Heading>
            <FormGroup className='tel'>
              <InputWrap>
                <Input
                  name='tel'
                  type='tel'
                  className={`form-control ${touched.tel ? 'is-touched' : ''} ${touched.tel && errors.tel ? 'is-invalid' : 'is-valid'} ${touched.tel && values.tel === '' ? 'is-empty' : ''}`}
                  value={values.tel}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Label className='form-control-label'>緊急電話番号</Label>
              </InputWrap>
              <ErrorMessage name='tel' />
            </FormGroup>

            <FormGroup className='street'>
              <InputWrap>
                <Input
                  name='street'
                  className={`form-control ${touched.street ? 'is-touched' : ''} ${touched.street && errors.street ? 'is-invalid' : 'is-valid'} ${touched.street && values.street === '' ? 'is-empty' : ''}`}
                  value={values.street}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Label className='form-control-label'>Street Address</Label>
              </InputWrap>
              <ErrorMessage name='street' />
            </FormGroup>

            <FormGroup className='apt'>
              <InputWrap>
                <Input
                  name='apt'
                  className={`form-control ${touched.apt ? 'is-touched' : ''} ${touched.apt && errors.apt ? 'is-invalid' : 'is-valid'} ${touched.apt && values.apt === '' ? 'is-empty' : ''}`}
                  value={values.apt}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Label className='form-control-label'>Apt, Suite, Building<Small>(任意)</Small></Label>
              </InputWrap>
            </FormGroup>

            <FormGroup className='city'>
              <InputWrap>
                <Input
                  name='city'
                  className={`form-control ${touched.city ? 'is-touched' : ''} ${touched.city && errors.city ? 'is-invalid' : 'is-valid'} ${touched.city && values.city === '' ? 'is-empty' : ''}`}
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}

                />
                <Label className='form-control-label'>City</Label>
              </InputWrap>
              <ErrorMessage name='city' />
            </FormGroup>

            <FormGroup className='state'>
              <InputWrap>

                <Input
                  name='state'
                  className={`form-control ${touched.state ? 'is-touched' : ''} ${touched.state && errors.state ? 'is-invalid' : 'is-valid'} ${touched.state && values.state === '' ? 'is-empty' : ''}`}
                  value={values.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Label className='form-control-label'>State</Label>
              </InputWrap>
              <ErrorMessage name='state' />
            </FormGroup>

            <FormGroup className='zipcode'>
              <InputWrap>

                <Input
                  name='zipcode'
                  type='text'
                  className={`form-control ${touched.zipcode ? 'is-touched' : ''} ${touched.zipcode && errors.zipcode ? 'is-invalid' : 'is-valid'} ${touched.zipcode && values.zipcode === '' ? 'is-empty' : ''}`}
                  value={values.zipcode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength='10'
                />
                <Label className='form-control-label'>Zip Code</Label>
              </InputWrap>
              <ErrorMessage name='zipcode' />
            </FormGroup>

            <FormGroup>
              <Label visible className='form-control-label'>育英メルマガに登録しますか？</Label>
              <RadioWrap className='newsletter'>
                {newsletter.map((el, i) => {
                  return (
                    <Radio key={i} className='form-control-label'>
                      <RadioInput
                        type='radio'
                        name='newsletter'
                        value={el.value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.newsletter === el.value}
                      />
                      {el.label}
                    </Radio>
                  )
                })}
              </RadioWrap>
              <ErrorMessage name='newsletter' />
            </FormGroup>
          </>
      }

      <FormGroup>
        <Heading>メッセージ</Heading>
        <Desc>
          お子さまについての留意点（行動面の心配事）、クラス編成のご希望 などをご記入ください。<br />
          クラス編成は年齢構成、男女比などを考慮して行いますのでご希望に添えない場合があることをご承知ください。
        </Desc>
        <Textarea
          name='message'
          type='textarea'
          className={`form-control ${touched.message && errors.message ? 'is-invalid' : 'is-valid'}`}
          value={values.message}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder=''
        />
        <ErrorMessage name='message' />
      </FormGroup>

      <Heading>お申し込み内容確認</Heading>

      <Amounts>
        <TotalClasses>参加者毎の小計<br /> <b>{calcStudentAmountTerm2(values.students, groups).replace(/<br>/g, '\n')}</b></TotalClasses>
        <br />
        <TotalClasses>合計クラス: <b>{totalClasses(values.students)}クラス</b></TotalClasses>
        <TotalClasses>合計金額: <b>{calcAmountTerm2(values.students, groups)}</b></TotalClasses>
      </Amounts>

      <FormGroup>
        {isBrowser &&
          <>
            <ReCAPTCHA
              ref={recaptchaRef}
              size='invisible'
              sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITEKEY}
              onChange={(response) => {
                console.log(response)
                axios.request({
                  method: 'POST',
                  baseURL: '/api/record/summer2020-term2',
                  headers: {
                    'Content-Type': 'application/json; charset=UTF-8'
                  },
                  data: JSON.stringify({
                    name: values.name || '',
                    email: values.email || '',
                    tel: values.tel || '',
                    street: values.street || '',
                    apt: values.apt || '',
                    city: values.city || '',
                    state: values.state || '',
                    zipcode: values.zipcode || '',
                    payment: values.payment,
                    newsletter: values.newsletter,
                    students: values.students,
                    message: values.message
                  })
                })
                  .then((res) => {
                    setTimeout(() => {
                      setSubmitting(false)
                      setStatus({state: 'Success'})
                      redirectToCheckout(res.data.airtable)
                    }).catch(() => {
                      notifyError()
                      setSubmitting(false)
                      recaptchaRef.current.reset()
                      setStatus({state: 'Error'})
                    })
                  }, 200)

                recaptchaRef.current.reset()
              }}
              onErrored={() => {
                recaptchaRef.current.reset()
              }}
            />

            <ErrorMessage name='recaptcha' />
          </>}
      </FormGroup>

      <FormGroup>
        <Checkbox
          name='agree1'
          value='参加規約に同意した上でニューヨーク育英オンラインサマースクール＆キャンプに申し込みます。'
          onBlur={handleBlur}
          wrapperClass='first'
        />
        <TermsLink><ExternalLink target='_blank' href='/terms/summer-2020-term2'>参加規約へ</ExternalLink></TermsLink>

        <Checkbox
          name='agree2'
          value='納入方法、金額、キャンセルの方法、及び参加条件・規定について理解しました。'
          onBlur={handleBlur}
        />
        <Checkbox
          name='agree3'
          value='参加にあたり学園の方針に従います。'
          onBlur={handleBlur}
        />
        {((touched.agree1 && touched.agree2 && touched.agree3) || submitCount > 0) &&
        (values.agree1.length === 0 || values.agree2.length === 0 || values.agree3.length === 0) &&
          <Error>お申し込みはには全ての項目への同意が必要です。</Error>}

      </FormGroup>
      {submitCount > 0 && (Object.keys(errors).length || (errors && errors.students && Object.keys(errors.students).length)) &&
        <Error>未入力の項目、間違った入力内容がござます。<br /> 入力欄にに戻り赤く囲われた箇所をご確認ください。</Error>}

      <ButtonWrapper>
        <Button
          type='submit'
          className='btn btn-outline-primary'
          disabled={isSubmitting || (status && status.state === 'Success')}
        >
          {isSubmitting ? '送信中...' : (status && status.state === 'Success') ? '決済ページへ移動中' : 'お申し込み'}
        </Button>
        {(isSubmitting || (status && status.state)) && <Redirect>送信完了後に決済ページへ移動します。そのままお待ちください。</Redirect>}
      </ButtonWrapper>

      {process.env.NODE_ENV === 'development' && <Debug />}
      {/* <Debug /> */}

      <Toastify
        enableMultiContainer
        containerId='Success'
        position='top-right'
        autoClose={8000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        pauseOnHover
        className='myToast'
      />
      <Toastify
        enableMultiContainer
        containerId='Error'
        position='top-right'
        autoClose={8000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        pauseOnHover
        className='myToast'
      />

    </Form>
  )
}

const Summer2020Form = withFormik({
  mapPropsToValues: () => (
    {
      agree1: [],
      agree2: [],
      agree3: [],
      name: '',
      tel: '',
      email: '',
      street: '',
      apt: '',
      zipcode: '',
      city: '',
      state: '',
      message: '',
      payment: 'クレジットカード',
      newsletter: '',
      recaptcha: '',
      students: [
        {
          studentNameEn: '',
          studentNameJa: '',
          studentEmail: '',
          studentGender: '',
          studentBirthdayDay: '',
          studentBirthdayMonth: '',
          studentBirthdayYear: '',
          studentPlaceOfBirth: '',
          studentGradeInJp: '',
          studentGradeInUs: '',
          studentClassesPreN: [],
          studentClasses: {
            kinder1st: [],
            kinder2nd: [],
            reviewAm: [],
            reviewPm: [],
            backToSchool: [],
            lang: [],
            workshop: []
          },
          studentSchool: '',
          studentSchoolLocation: '',
          studentWeekendSchool: '',
          studentYearsLivingInUs: '',
          studentLanguageClass: '',
          studentJapaneseLevel: '',
          studentEnglishLevel: '',
          studentBus: '',
          studentLunch: '',
          studentJcs: '',
          studentTshirt: '',
          studentJcsClass: '',
          studentLastYear: '',
          studentMedia: '',
          isStudent: ''
        }
      ]
    }
  ),

  handleSubmit: async (values, {props, setSubmitting, setStatus, validateForm}) => {
    await validateForm().then(res => {
      setStatus({state: 'Submitting'})
      recaptchaRef.current.execute()
    }).catch(err => {
      console.log(err)
    })
  },

  validationSchema: Yup.object().shape({
    agree1: Yup.array().required(''),
    agree2: Yup.array().required(''),
    agree3: Yup.array().required(''),

    name: Yup.string().when('students', {
      is: (val) => val.some(el => el.isStudent === 'true'),
      then: Yup.string().required('保護者氏名を入力してください')
    }),

    email: Yup.string().when('students', {
      is: (val) => val.some(el => el.isStudent === 'true'),
      then: Yup.string().email('無効なメールアドレスです。入力内容をご確認ください。').required('メールアドレスを入力してください')
    }),

    tel: Yup.string().required('電話番号を入力してください'),
    street: Yup.string().required('Street Adressを入力してください'),
    city: Yup.string().required('Cityを入力してください'),
    state: Yup.string().required('Stateを入力してください'),
    zipcode: Yup.number().typeError('半角数字で入力してください').min(1, '無効な値です。正しいZip Codeを入力してください').required('Zip Codeを入力してください'),
    payment: Yup.string().required('お支払い方法を選択してください'),
    newsletter: Yup.string().required('メルマガ配信希望を選択してください'),

    students: Yup.array()
      .of(
        Yup.object().shape({
          studentNameEn: Yup.string().required('英語 氏名を入力してください'),
          studentNameJa: Yup.string().required('日本語 氏名を入力してください'),
          studentEmail: Yup.string().matches(/^([a-z0-9](\.?[a-z0-9]){5,}@g(oogle)?mail\.com)|((.*)@floatingdots\.com)|(.*)@nyikuei\.org$/, '有効なGmailを入力してください。'),
          studentGender: Yup.string().required('性別を選択してください'),
          studentBirthdayYear: Yup.number().typeError('誕生年を半角数字で入力してください').min(1900, '無効な値です。誕生年を半角数字で入力してください').max(2020, '無効な値です。半角数字で入力してください').required('誕生年を入力してください'),
          studentBirthdayMonth: Yup.number().typeError('誕生月を半角数字で入力してください').min(1, '無効な値です。誕生月を半角数字で入力してください').max(12, '無効な値です。半角数字で入力してください').required('誕生月を入力してください'),
          studentBirthdayDay: Yup.number().typeError('誕生日を半角数字で入力してください').min(1, '無効な値です。誕生日を半角数字で入力してください').max(31, '無効な値です。半角数字で入力してください').required('誕生日を入力してください'),

          studentGradeInJp: Yup.string().required('日本式での学年してください'),

          studentPlaceOfBirth: Yup.string().when('isStudent', {
            is: (val) => val === 'true',
            then: Yup.string().required('出生国を入力してください')
          }),

          studentYearsLivingInUs: Yup.number().when('isStudent', {
            is: (val) => val === 'true',
            then: Yup.number().typeError('在米年数を半角数字で入力してください').required('在米年数を入力してください'),
            otherwise: Yup.number().transform(emptyStringToNull).nullable()
          }),

          studentGradeInUs: Yup.string().when('isStudent', {
            is: (val) => val === 'true',
            then: Yup.string().required('アメリカ式での学年してください')
          }),

          studentJcs: Yup.string().when('isStudent', {
            is: (val) => val === 'true',
            then: Yup.string().required('はい・いいえのどちらかを選択してください')
          }),
          studentLastYear: Yup.string().when('isStudent', {
            is: (val) => val === 'true',
            then: Yup.string().required('はい・いいえのどちらかを選択してください')
          }),
          studentJcsClass: Yup.string().when('studentJcs', {
            is: (val) => val === 'true',
            then: Yup.string().required('ニューヨーク育英学園の在学部門を入力してください')
          }),

          studentSchool: Yup.string().when('studentGradeInJp', {
            is: (val) => val.includes('小学') || val.includes('中学') || val.includes('高校'),
            then: Yup.string().required('在学校名を入力してください')
          }),
          studentSchoolLocation: Yup.string().when('studentGradeInJp', {
            is: (val) => val.includes('小学') || val.includes('中学') || val.includes('高校'),
            then: Yup.string().required('在学校所在地を入力してください')
          }),

          studentEnglishLevel: Yup.string().when('studentGradeInJp', {
            is: (val) => val.includes('幼児部'),
            then: Yup.string().required('どれかひとつを選択してください。')
          }),
          studentJapaneseLevel: Yup.string().when('studentGradeInJp', {
            is: (val) => val.includes('幼児部'),
            then: Yup.string().required('どれかひとつを選択してください。')
          }),

          studentClassesPreN: Yup.array().when('studentGradeInJp', {
            is: (val) => val.includes('未就園'),
            then: Yup.array().required('参加する日を選択してください')
          }),
          studentTshirt: Yup.string().required('Tシャツのサイズを選択してください'),
          studentMedia: Yup.string().required('写真・動画掲載の可否を選択してください'),

          studentClasses: Yup.object().shape({
            kinder1st: Yup.array().of(Yup.string().matches(/^(?!.*(limit)).+$|^$/, '定員に達しているクラスが選択されています。')),
            kinder2nd: Yup.array().of(Yup.string().matches(/^(?!.*(limit)).+$|^$/, '定員に達しているクラスが選択されています。')),
            reviewAm: Yup.array().of(Yup.string().matches(/^(?!.*(limit)).+$|^$/, '定員に達しているクラスが選択されています。')),
            reviewPm: Yup.array().of(Yup.string().matches(/^(?!.*(limit)).+$|^$/, '定員に達しているクラスが選択されています。')),
            backToSchool: Yup.array().of(Yup.string().matches(/^(?!.*(limit)).+$|^$/, '定員に達しているクラスが選択されています。')),
            lang: Yup.array().of(Yup.string().matches(/^(?!.*(limit)).+$|^$/, '定員に達しているクラスが選択されています。')),
            workshop: Yup.array().of(Yup.string().matches(/^(?!.*(limit)).+$|^$/, '定員に達しているクラスが選択されています。'))
          })
        })
      )
  })
})(InnerForm)

export default Summer2020Form
